<template>
  <div class="subscriptions-billing">
    <list 
      title="Billing"
      :items="customersData"
    />
  </div>
</template>
<script>
import List from '@/components/list'

export default {
  props: {
    subscriptionsData: {
      required: true
    },
    customersData: {
      required: true
    },
    userData: {
      required: true
    }
  },
  name: 'SubscriptionsBilling',
  components: {
    List
  }
}
</script>
<template>
  <v-data-table
    :items="items"
    :headers="headers"
    item-key="value"
    item-text="text"
    hide-default-footer
    v-bind="$attrs"
    v-on="$listeners"
  >

  </v-data-table>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    headers: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  name: 'SubscriptionsTable'
}
</script>
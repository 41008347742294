<template>
  <loader-hoc
    style="height: 100%;"
    v-model="inProcess"
  >
    <status-toolbar
      class="mb-4"
      v-if="customer"
      :items="[{ value: true, text: 'ACTIVE', color: 'green' }, { value: false, text: 'INACTIVE', color: 'red' }]"
      :item="customer.Enabled"
    />
    <div class="display-1">Customer </div>
    <!--<router-view /> -->
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <card>
            <customer-insight
              :customers-data="customer"
              :subscriptions-data="subscriptions"
              :user-data="userData"
              :loading="inProcessSubscription"
              @click:row="onClickRow"
            />
          </card>
        </v-col>
        <v-col cols="6">
          <card>
            <customer-billing
              :customers-data="customer"
              :subscriptions-data="subscriptions"
              :user-data="userData"
            />
          </card>
        </v-col>
        <v-col cols="12">
          <card
          >
            <customer-history
              v-if="!inProcessSubscription"
              :customers-data="customer"
              :subscriptions-data="subscriptions"
              :user-data="userData"
            />
          </card>
        </v-col>
      </v-row>
    </v-container>
  </loader-hoc>
</template>
<script>
import StatusToolbar from '@/components/status-toolbar'
import Card from '@/components/card'
import CustomerInsight from '@/components/customer/insight'
import CustomerBilling from '@/components/customer/billing'
import CustomerHistory from '@/components/customer/history'
import LoaderHoc from '@/components/loader-hoc'

export default {
  name: 'DashboardUserCustomer',
  components: {
    StatusToolbar,
    Card,
    CustomerInsight,
    CustomerBilling,
    CustomerHistory,
    LoaderHoc
  },
  data: () => ({
    userData: null,
    customer: null,
    subscriptions: [],
    inProcess: false,
    inProcessSubscription: true
  }),
  async created() {
    this.inProcess = true
    
    this.userData = await this.$store.dispatch('getCustomer', this.userId)

    const { externalData = {} } = this.userData[1]
    const { customers = {}, subscriptions = {} } = externalData.verifone

    this.customer = customers[this.customerId]
    this.inProcessSubscription = true

    // this.subscriptions = await this.$store.dispatch('getCustomerSubscriptions', this.customerId)
    this.subscriptions = Object.values(subscriptions).filter(subscription => subscription.CustomerReference == this.customerId)
    this.inProcessSubscription = false
    
    this.inProcess = false
  },
  computed: {
    userId() {
      const { userId } = this.$route.params
      return userId 
    },
    customerId() {
      const { customerId } = this.$route.params
      return customerId
    }
  },
  methods: {
    onClickRow(item) {
      const { SubscriptionReference: subscriptionId } = item
      const path = `/dashboard/user/${this.userId}/customer/${this.customerId}/subscription/${subscriptionId}`

      this.$router.push({ path })
    }
  }
}
</script>
<template>
  <div class="subscriptions-insight">
    <v-subheader>Insight</v-subheader>
    <subscriptions-table 
      :items="tableItems"
      :headers="tableHeaders"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>
<script>

import SubscriptionsTable from './table.vue'

export default {
  props: {
    subscriptionsData: {
      required: true
    },
    customersData: {
      required: true
    },
    userData: {
      required: true
    }
  },
  name: 'SubscriptionsInsight',
  components: {
    SubscriptionsTable
  },
  computed: {
    tableHeaders() {
      return Object.keys(this.subscriptionsData[0] || {}).map(key => {
        console.log(key)
        return ({ value: key, text: key })
      })
    },
    tableItems() {
      return this.subscriptionsData
    }
  }
}
</script>